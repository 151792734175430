/*
//////////////////////////////////////////////////////////////////////////////////
 COMBO BOX
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-combo-state-default {
  @apply relative;
}

.kit-combo-li {
  @apply relative cursor-pointer select-none py-1 px-2 hover:bg-bg-component-hover focus:bg-bg-component-hover focus:outline-none;
}

.kit-combo-mat-panel {
  @apply bg-bg-component rounded-xl border border-border-component-hover #{!important};
  .mat-mdc-menu-content {
    @apply p-0;
  }
}

/*
//////////////////////////////////////////////////////////////////////////////////
   DIALOG
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-side-panel {
  @apply absolute max-w-[100vw] mt-[66px] mr-2.5 #{!important};
  &.animated-in {
    @apply right-[-500px] transition-transform ease-in duration-500 translate-x-[-500px];
  }

  &.animated-off {
    @apply right-[-500px] transition-transform ease-in duration-500 translate-x-[500px];
  }

  .kit-dialog-content {
    @apply flex h-full;
  }

  .kit-dialog-container {
    @apply h-full;
  }

  .mdc-dialog__container {
    @apply transform-none #{!important};
    height: calc(100vh - 76px);
  }
}

.kit-dialog > kit-dialog-content {
  @apply flex max-h-[90vh];
}

.kit-dialog > mat-dialog-container.mat-dialog-container {
  @apply p-0 bg-transparent shadow-none;
}

.mdc-dialog__surface {
  @apply rounded-2xl bg-transparent shadow-none #{!important};
}

.kit-dialog-container {
  @apply w-full bg-bg-component;
}

/*
//////////////////////////////////////////////////////////////////////////////////
 DROP DOWN
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-dropdown {
  @apply bg-bg-component rounded-xl border border-border-component-secondary #{!important};

  .mat-mdc-menu-content:not(:empty) {
    @apply p-0 #{!important};
  }
}

/*
//////////////////////////////////////////////////////////////////////////////////
   ICONS
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-icon-xs {
  @apply w-3 h-3 #{!important};
}

.kit-icon-small {
  @apply w-4 h-4 #{!important};
}

.kit-icon-md {
  @apply w-5 h-5 #{!important};
}

.kit-icon-default {
  @apply w-6 h-6 #{!important};
}

.kit-icon {
  &-current {
    fill: currentColor;
  }

  &-white {
    path {
      @apply fill-white;
    }
  }

  &-icon {
    path {
      @apply fill-icon;
    }
  }

  &-secondary {
    path {
      @apply fill-icon-secondary;
    }
  }

  &-inverse {
    path {
      @apply fill-icon-inverse;
    }
  }

  &-inverse-hover {
    path {
      @apply fill-icon-inverse-hover;
    }
  }

  &-disabled {
    path {
      @apply fill-icon-disabled;
    }
  }

  &-success {
    path {
      @apply fill-icon-success;
    }
  }

  &-success-hover {
    path {
      @apply fill-icon-success-hover;
    }
  }

  &-onsuccess {
    path {
      @apply fill-icon-onsuccess;
    }
  }

  &-brand {
    path {
      @apply fill-icon-brand;
    }
  }

  &-onbrand {
    path {
      @apply fill-icon-onbrand;
    }
  }

  &-warning {
    path {
      @apply fill-icon-warning;
    }
  }

  &-onwarning {
    path {
      @apply fill-icon-onwarning;
    }
  }

  &-info {
    path {
      @apply fill-icon-info;
    }
  }

  &-info-hover {
    path {
      @apply fill-icon-info-hover;
    }
  }

  &-oninfo {
    path {
      @apply fill-icon-oninfo;
    }
  }
}

/*
//////////////////////////////////////////////////////////////////////////////////
   RADIO
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-radio {
  @apply flex;
}

.kit-radio-checkmark {
  @apply h-5 w-5 inline-flex items-center place-content-center rounded-full bg-icon-inverse cursor-pointer;
}

.kit-radio input:checked ~ .kit-radio-checkmark {
  @apply bg-border-pressed;
}

.kit-radio:hover input ~ .kit-radio-checkmark {
  @apply bg-border-pressed;
}

.kit-radio-checkmark:after {
  content: "";
  @apply absolute;
}

.kit-radio input:checked ~ .kit-radio-checkmark:after {
  @apply block border border-border-pressed
}

.kit-radio .kit-radio-checkmark:after {
  @apply flex h-[14px] w-[14px] rounded-full bg-white shadow-xl;
}

/*
//////////////////////////////////////////////////////////////////////////////////
   RIPPLE EFFECT
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-ripple {
  @apply absolute rounded-full scale-0 pointer-events-none;
  //background-color: rgba(0, 0, 0, 0.3);
  animation: ripple-animation 0.7s linear;
}

@keyframes ripple-animation {
  0% {
    transform: scale(0);
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*
//////////////////////////////////////////////////////////////////////////////////
   SCROLL BAR
//////////////////////////////////////////////////////////////////////////////////
*/

.scrollbar::-webkit-scrollbar {
  @apply w-1 bg-bg-component-hover;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  @apply bg-bg-component-hover;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-full bg-bg-hover border-border-hover;
}

/* hide scroll buttons */
.scrollbar::-webkit-scrollbar-button {
  @apply hidden;
}

/*
//////////////////////////////////////////////////////////////////////////////////
   TABS
//////////////////////////////////////////////////////////////////////////////////
*/

.kit-tab {
  @apply px-3 pt-0.5 cursor-pointer rounded-t border-b-2 hover:bg-bg-hover transition ease-in-out duration-300;
}

.kit-tab-default {
  @apply text-txt-secondary border-b-transparent hover:border-b-border-hover;
}

.kit-tab-active {
  @apply border-b-border-pressed pb-[6px];
}

/*
//////////////////////////////////////////////////////////////////////////////////
   TOOLTIP
//////////////////////////////////////////////////////////////////////////////////
*/

.uikit-tooltip {
  pointer-events: none;
  z-index: 9999;
  position: absolute;

  &-top {
    @extend .uikit-tooltip;
    transform: translate(-50%, calc(-100% - 8px)); // Permet de replacer au bon endroit, par défaut se trouve en bas
  }

  &-bottom {
    @extend .uikit-tooltip;
    transform: translate(-50%, calc(8px));
  }

  &-left {
    @extend .uikit-tooltip;
    transform: translate(calc(-100% - 8px), -50%); // Permet de replacer au bon endroit, par défaut se trouve à droite
  }

  &-right {
    @extend .uikit-tooltip;
    transform: translate(calc(8px), -50%);
  }

  &-default {
    @apply bg-bg-inverse text-txt-inverse;
  }
}

.uikit-tooltip-content {
  @apply pointer-events-none relative rounded-lg text-xs whitespace-nowrap shadow-lg transition-opacity duration-200;
}

.uikit-tooltip-content:before {
  @apply absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.uikit-tooltip-arrow {
  @apply absolute h-4 w-4 transform;
  &-top {
    @apply left-1/2 top-[99%] -translate-x-1/2 -translate-y-[5px];
  }

  &-bottom {
    @apply left-1/2 bottom-[99%] -translate-x-1/2 translate-y-[5px] rotate-180;
  }

  &-left {
    @apply top-1/2 left-[99%] -translate-y-1/2 -translate-x-[5px] -rotate-90;
  }

  &-right {
    @apply top-1/2 right-[100%] -translate-y-1/2 translate-x-[5px] rotate-90;
  }
}

/*
//////////////////////////////////////////////////////////////////////////////////
   OTHERS
//////////////////////////////////////////////////////////////////////////////////
*/

.backdrop {
  @apply absolute opacity-30 top-0 left-0 right-0 bottom-0;
  background-color: #000000;
}

.cdk-visually-hidden {
  display: none;
}

.flex-2 {
  flex: 2 1 0;
}

.flex-3 {
  flex: 3 1 0;
}

/* Default height fors tabs, input, button ... */
.kit-height-default {
  @apply h-10 max-h-10
}

.kit-height-large {
  @apply h-12 max-h-12
}

.kit-height-small {
  @apply h-6 max-h-6
}

.kit-page-scroll-default {
  overflow: inherit !important;
}

.kit-page-scroll-disabled {
  overflow: hidden !important;
}
