@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';
@import '../../../node_modules/ngx-toastr/toastr.css';

@import 'projects/kit/styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import '../../kit/styles/kit.scss';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  overflow-y: auto;
}

html, body {
  @apply h-full w-full m-0;
}

body {
  @apply bg-bg text-txt overflow-x-hidden;
}

.mat-header-cell {
  color: white;
}

.divider {
  @apply border-b border-b-border-inverse;
}

.centered {
  justify-content: center;
}

.right-icon {
  top: 15px;
  right: 10px;
}

.btr-form {
  @apply bg-bg-component text-txt-secondary border border-border-component-secondary hover:border-border-component-secondary-hover focus:outline-none focus:border-[3px] focus:border-border-brand;
}

.btr-input {
  @extend .btr-form;
  @apply h-10 px-2.5 rounded-lg;
}

.btr-input-area {
  @extend .btr-form;
  @apply min-h-[40px] max-h-36 px-2.5 rounded-lg;
}

.btr-input-date {
  @extend .btr-form;
  @apply h-10 px-8 rounded-lg;
}

.btr-input:disabled,
.btr-input-area:disabled,
.btr-input-date:disabled {
  @apply bg-bg-component-pressed;
}

/*
//////////////////////////////////////////////////////////////////////////////////
   BTN
//////////////////////////////////////////////////////////////////////////////////
*/

.btr-btn {
  @apply h-10;
}

.btr-btn {
  &-default {
    @extend .btr-btn;
    @apply leading-none rounded-md px-10;
  }

  &-default-icon {
    @extend .btr-btn;
    @apply rounded-md px-10;
  }

  &-round {
    @extend .btr-btn;
    @apply leading-none rounded-full px-4 py-2;
  }

  &-round-icon {
    @extend .btr-btn;
    @apply rounded-full px-4 py-2;
  }

  &-brand {
    @apply bg-bg-brand text-txt-onbrand hover:bg-bg-brand-hover;
  }

  &-outline {
    @apply border border-border-disabled hover:bg-bg-component-hover;
  }
}

.btr-fab {
  @extend .btr-btn;
  @apply w-10 rounded-md flex items-center justify-center;
}

.btr-date-suffix {
  @apply absolute flex h-10 w-10 top-0 right-0 items-center place-content-center;
}

.btr-error {
  @apply flex items-center text-sm float-right;
}

.text-error {
  @apply italic text-sm;
}

.input-error {
  @apply bg-bg-warning-third border border-border-warning text-txt-warning;
}

.mat-dialog-content,
.mat-dialog-container {
  overflow: unset !important;
}

.btr-badge-small {
  @apply flex w-fit rounded-full space-x-1 px-2 py-px;
}

.btr-badge-default {
  @apply flex h-5 w-fit items-center rounded-full space-x-0.5 px-1 py-0.5;
}

.btr-widget {
  @apply bg-bg-component border border-bg-component rounded-xl shadow-lg;
}

.btr-widget-hover {
  @apply hover:shadow-xl hover:border-border-component-hover;
}

.btr-icon-hover {
  @apply flex h-9 w-9 items-center place-content-center cursor-pointer rounded-full transition ease-in-out hover:bg-bg-component-hover;
}

.btr-icon-hover-hover {
  @apply flex h-9 w-9 items-center place-content-center cursor-pointer rounded-full transition ease-in-out hover:bg-bg-hover;
}
