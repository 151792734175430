:root {
  --btr-light-blue: #1d388f9c;
  --btr-light-red: #9d2e169c;
  --btr-dark-blue: #1d388f;
  --btr-dark-red: #9d2e16;
  --btr-green: #12b711;
  --btr-olive: #587E6D;
  --btr-orange: #cb8300;
  --btr-red: #ff8c8c;
  --btr-redwood-40: #d8aba2;
  --btr-redwood-100: #9d2e16;
  --btr-light-grey: #f7f7f7;
  --btr-light-pink: #ff8edc9c;
  --btr-dark-pink: #ff8edc;
  --btr-grey: #808080;
  --btr-grey-60: #666666;
  --btr-grey-900: #181719;
  --btr-secondary: #4E5DE9;

  --kit-grey-200:#9a9898;
  --kit-black:#000000;
  --kit-white:#ffffff;
}

$btr-light-blue: var(--btr-light-blue);
$btr-light-red: var(--btr-light-red);
$btr-dark-blue: var(--btr-dark-blue);
$btr-dark-red: var(--btr-dark-red);
$btr-green: var(--btr-green);
$btr-olive: var(--btr-olive);
$btr-orange: var(--btr-orange);
$btr-red: var(--btr-red);
$btr-redwood-40: var(--btr-redwood-40);
$btr-redwood-100: var(--btr-redwood-100);
$btr-light-grey: var(--btr-light-grey);
$btr-light-pink: var(--btr-light-pink);
$btr-dark-pink: var(--btr-dark-pink);
$btr-dark-pink: var(--btr-dark-pink);
$btr-grey: var(--btr-grey);
$btr-grey-60: var(--btr-grey-60);
$btr-grey-900: var(--btr-grey-900);
$btr-secondary: var(--btr-secondary);

// Set color in css
.c-light-blue {color: $btr-light-blue;}
.c-light-red {color: $btr-light-red;}
.c-dark-blue {color: $btr-dark-blue;}
.c-dark-red {color: $btr-dark-red;}
.c-green {color: $btr-green;}
.c-olive {color: $btr-olive;}
.c-orange {color: $btr-orange;}
.c-red {color: $btr-red;}
.c-redwood-40 {color: $btr-redwood-40;}
.c-redwood-100 {color: $btr-redwood-100;}
.c-light-pink {color: $btr-light-pink;}
.c-dark-pink {color: $btr-dark-pink;}
.c-grey {color: $btr-grey;}
.c-grey-60 {color: $btr-grey-60;}
.c-grey-900 {color: $btr-grey-900;}
.c-secondary {color: $btr-secondary;}

// Set background-color in css
.bg-light-blue {background-color: $btr-light-blue;}
.bg-light-red {background-color: $btr-light-red;}
.bg-dark-blue {background-color: $btr-dark-blue;}
.bg-dark-red {background-color: $btr-dark-red;}
.bg-green {background-color: $btr-green;}
.bg-olive {background-color: $btr-olive;}
.bg-orange {background-color: $btr-orange;}
.bg-red {background-color: $btr-red;}
.bg-redwood-40 {background-color: $btr-redwood-40;}
.bg-redwood-100 {background-color: $btr-redwood-100;}
.bg-light-pink {background-color: $btr-light-pink;}
.bg-dark-pink {background-color: $btr-dark-pink;}
.bg-grey {background-color: $btr-grey;}
.bg-grey-60 {background-color: $btr-grey-60;}
.bg-grey-900 {background-color: $btr-grey-900;}
.bg-secondary {background-color: $btr-secondary;}
